/* eslint-disable no-console */
/**
 * # About Page
 */

/**
 * ## Imports
 */
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { ClientName } from 'client/clientConfig';
// import PageFooter from 'common/components/PageFooter/PageFooter';
// import PageHeader from 'common/components/PageHeader/PageHeader';
// import DownloadOffer from 'common/components/DownloadOffer/DownloadOffer';
import { isMacOS } from 'lib/userAgent';
// import { submitMetrics } from '../../lib/submitMetrcis';
// import { queryStringToObject } from '../../lib/query';

/**
 * ## Component
 */
const DownloadPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isAdblockClient = ClientName === 'adblock';
    // const appClassName = `app ${ClientName ? `app--${ClientName}` : ''} app--home`;

    // useEffect(() => {
    //     submitMetrics({
    //         Category: 'BrowserLandingPage',
    //         Action: 'view',
    //         Object: 'webpage',
    //         ReferalSource: queryStringToObject(location?.search || ''),
    //     });
    //     setTimeout(() => navigate(isMacOS ? '/download-mac' : '/download-windows'), 3000);
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    useEffect(() => {
        if (isAdblockClient) {
            navigate('/');
        } else {
            navigate(isMacOS ? '/download-mac' : '/download-windows', { state: { ...location.state } });
        }
    });

    // return null;

    // return (
    //     <div className={appClassName}>
    //         <h1 className="sr-only">Sonic Browser - Download</h1>
    //         <PageHeader clientName={ClientName} />
    //         <DownloadOffer />
    //         <PageFooter clientName={ClientName} />
    //     </div>
    // );
};

export default DownloadPage;
