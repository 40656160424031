/**
 * # After Download Page
 */

/**
 * ## Imports
 */
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ClientName } from 'client/clientConfig';
import PageFooter from 'common/components/PageFooter/PageFooter';
import PageHeader from 'common/components/PageHeader/PageHeader';
import AfterDownloadInstructions from 'common/components/AfterDownloadInstructions/AfterDownloadInstructions';
import { useDownload } from 'hooks/useDownload';
import { submitMetrics } from '../../lib/submitMetrcis';
import { queryStringToObject } from '../../lib/query';

/**
 * ## Component
 */
const AfterDownloadPage = ({ OS }: { OS?: string }) => {
    const location = useLocation();
    const navigate = useNavigate();
    // const isNewLayout = location?.state?.isNewLayout;
    const isNewLayout = false;
    const { downloadBrowser } = useDownload(location?.search || '');
    const appClassName = `app ${ClientName ? `app--${ClientName}` : ''} app--after-download`;
    const isAdblockClient = ClientName === 'adblock';

    useEffect(() => {
        if (isAdblockClient) {
            navigate('/');
        } else {
            submitMetrics({
                Category: 'BrowserLandingPage',
                Action: 'view',
                Object: 'autodownload',
                ReferalSource: queryStringToObject(location?.search || ''),
                testPage: isNewLayout ? 'handholding' : 'baseline',
            });
            downloadBrowser(OS, null, isNewLayout);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isAdblockClient) {
        return null;
    }

    return (
        <div className={appClassName}>
            <h1 className="sr-only">{ClientName.charAt(0).toUpperCase() + ClientName.slice(1)} Browser - Download</h1>
            <PageHeader clientName={ClientName} isMenuHidden />
            <AfterDownloadInstructions OS={OS} isNewLayout={isNewLayout} />
            <PageFooter clientName={ClientName} />
        </div>
    );
};

export default AfterDownloadPage;
